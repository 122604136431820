define("discourse/plugins/discourse-user-feedbacks/discourse/controllers/user-feedbacks", ["exports", "@ember/controller", "@ember/object", "discourse/lib/ajax", "I18n", "discourse-common/utils/decorators"], function (_exports, _controller, _object, _ajax, _I18n, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _controller.default.extend((_dec = (0, _decorators.default)("feedback_to_id"), _dec2 = (0, _decorators.default)("rating"), (_obj = {
    rating: 0,
    review: "",
    readOnly: false,
    placeholder: _I18n.default.t("discourse_user_feedbacks.user_feedbacks.user_review.placeholder"),
    canGiveFeedback(feedback_to_id) {
      return feedback_to_id !== this.currentUser && this.currentUser.id;
    },
    disabled(rating) {
      return !parseInt(rating) > 0;
    },
    createFeedback() {
      this.set("readOnly", true);
      (0, _ajax.ajax)("/user_feedbacks", {
        type: "POST",
        data: {
          rating: parseInt(this.rating),
          review: this.review,
          feedback_to_id: this.feedback_to_id
        }
      }).then(response => {
        this.model.feedbacks.unshiftObject(response.user_feedback);
        this.set("rating", 0);
        this.set("review", "");
      });
    }
  }, (_applyDecoratedDescriptor(_obj, "canGiveFeedback", [_dec], Object.getOwnPropertyDescriptor(_obj, "canGiveFeedback"), _obj), _applyDecoratedDescriptor(_obj, "disabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "disabled"), _obj), _applyDecoratedDescriptor(_obj, "createFeedback", [_object.action], Object.getOwnPropertyDescriptor(_obj, "createFeedback"), _obj)), _obj)));
});