define("discourse/plugins/discourse-user-feedbacks/discourse/components/rating-input", ["exports", "@ember/component", "@ember/object", "discourse-common/utils/decorators"], function (_exports, _component, _object, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("value"), _dec2 = (0, _decorators.default)("value"), _dec3 = (0, _decorators.default)("value"), _dec4 = (0, _decorators.default)("value"), _dec5 = (0, _decorators.default)("value"), _dec6 = (0, _decorators.default)("value"), _dec7 = (0, _decorators.default)("value"), _dec8 = (0, _decorators.default)("value"), _dec9 = (0, _decorators.default)("value"), _dec10 = (0, _decorators.default)("value"), (_obj = {
    classNames: ["user-ratings"],
    value: 0,
    checkedOne: false,
    checkedTwo: false,
    checkedThree: false,
    checkedFour: false,
    checkedFive: false,
    percentageOne: 68,
    didReceiveAttrs() {
      this._super(...arguments);
      this.changeRating();
    },
    changeRating(value) {
      if (value && this.readOnly) return;
      if (value > 0) {
        this.set("value", value);
      } else {
        this.set("value", this.value);
      }
    },
    checkedOne(value) {
      if (parseInt(value) >= 1) {
        return true;
      }
      return false;
    },
    checkedTwo(value) {
      if (parseInt(value) >= 2) {
        return true;
      }
      return false;
    },
    checkedThree(value) {
      if (parseInt(value) >= 3) {
        return true;
      }
      return false;
    },
    checkedFour(value) {
      if (parseInt(value) >= 4) {
        return true;
      }
      return false;
    },
    checkedFive(value) {
      if (parseInt(value) >= 5) {
        return true;
      }
      return false;
    },
    percentageOne(value) {
      if (!this.checkedOne) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageTwo(value) {
      if (this.checkedOne && !this.checkedTwo) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageThree(value) {
      if (this.checkedTwo && !this.checkedThree) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageFour(value) {
      if (this.checkedThree && !this.checkedFour) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    },
    percentageFive(value) {
      if (this.checkedFour && !this.checkedFive) {
        return Math.round(value * 100) / 100 % 1 * 100;
      }
      return 0;
    }
  }, (_applyDecoratedDescriptor(_obj, "changeRating", [_object.action], Object.getOwnPropertyDescriptor(_obj, "changeRating"), _obj), _applyDecoratedDescriptor(_obj, "checkedOne", [_dec], Object.getOwnPropertyDescriptor(_obj, "checkedOne"), _obj), _applyDecoratedDescriptor(_obj, "checkedTwo", [_dec2], Object.getOwnPropertyDescriptor(_obj, "checkedTwo"), _obj), _applyDecoratedDescriptor(_obj, "checkedThree", [_dec3], Object.getOwnPropertyDescriptor(_obj, "checkedThree"), _obj), _applyDecoratedDescriptor(_obj, "checkedFour", [_dec4], Object.getOwnPropertyDescriptor(_obj, "checkedFour"), _obj), _applyDecoratedDescriptor(_obj, "checkedFive", [_dec5], Object.getOwnPropertyDescriptor(_obj, "checkedFive"), _obj), _applyDecoratedDescriptor(_obj, "percentageOne", [_dec6], Object.getOwnPropertyDescriptor(_obj, "percentageOne"), _obj), _applyDecoratedDescriptor(_obj, "percentageTwo", [_dec7], Object.getOwnPropertyDescriptor(_obj, "percentageTwo"), _obj), _applyDecoratedDescriptor(_obj, "percentageThree", [_dec8], Object.getOwnPropertyDescriptor(_obj, "percentageThree"), _obj), _applyDecoratedDescriptor(_obj, "percentageFour", [_dec9], Object.getOwnPropertyDescriptor(_obj, "percentageFour"), _obj), _applyDecoratedDescriptor(_obj, "percentageFive", [_dec10], Object.getOwnPropertyDescriptor(_obj, "percentageFive"), _obj)), _obj)));
});